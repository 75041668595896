<template>
    <el-container>
        <el-header style="height:210px;padding:0;">
                <div style="height:40px;margin-bottom:24px;min-width:740px;">
                    <div class="divider"></div>
                    <div class="detail-header-left">
                        <span class="title">{{scriptBasic.title}}</span>
                        <el-tag size="mini" :type="global.STATES[scriptBasic.statusCode].type">{{global.STATES[scriptBasic.statusCode].label}}</el-tag>
                    </div>  
                    <div style="float:right">
                        <el-button icon="el-icon-edit" size="medium" 
                                   style="width:98px;"
                                   @click="visible=true"
                                   :disabled="!editAble">
                            编辑
                        </el-button>
                    </div>
                </div>
                <div style="height:100px;width:100%;min-width:740px;margin-bottom:50px;">
                    <div class="description">                   
                        <div ref="des">
                            <span>任务描述：</span>
                            <span style="color:#595959;">{{scriptBasic.description}}</span>
                        </div>
                        <div>
                            <span>任务封面：</span>
                            <el-image v-if="scriptBasic.coverUrl" style="height:90px;vertical-align:-80px;margin-left:0" :src="scriptBasic.coverUrl" fit="contain" :preview-src-list="[scriptBasic.coverUrl]"></el-image>
                            <span v-else>暂无封面</span>
                        </div>
                    </div>
                    <div class="detail-card">
                        <div class="firstline">
                            <div style="float:left;margin-left:16px;">
                                <span style="color:rgba(56, 121, 238, 1)">已完成</span>
                            </div>
                            <div style="float:right;margin-right:41px;"> 
                                <span style="color:rgba(111, 105, 105, 1)">未完成</span>
                            </div>
                        </div>
                        <div class="secondline">
                            <div style="float:left;margin:0 20px;min-width:53px;">
                                <span style="color:rgba(56, 121, 238, 1)">{{commitCount}}</span>
                            </div>   
                            <div style="float:left">
                                <span style="color:rgba(56, 121, 238, 1)">/</span>
                            </div>  
                            <div style="float:left;margin:0 0 0 20px;">
                                <span style="color:rgba(111, 105, 105, 1)">{{unCommitCount}}</span>
                            </div>       
                        </div>
                    </div>
                </div>
        </el-header>
        <div>
            <div style="height:40px;">
                <div class="divider"></div>
                <div style="float:left;font-size:18px;">
                    <span>已完成任务列表</span>     
                </div>
            </div>
            <div class="secondHeader">
                <div class="inputGroup">
                    <el-input placeholder="输入投稿标题关键字、评论员姓名搜索"
                                v-model="searchData.keyWord"
                                style="width: 300px"
                                prefix-icon="el-icon-search">
                    </el-input>
                    <el-cascader placeholder="请选择队伍"
                                v-model="searchData.departmentId"
                                :options="selectDepartmentOptions"
                                :props="{expandTrigger:'hover'}"
                                :disabled="!editAble"
                                clearable>
                    </el-cascader>
                    <el-button type="text" @click="search(false)">搜索</el-button>
                </div>
            </div>
            <div>
                <el-table :data="scriptDetail" v-loading="table_loading">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-form label-position="center" class="table-expand">
                                <el-form-item label="文章标题：" label-width="82px">
                                    <span style="white-space:pre-wrap">{{props.row.articleTitle}}</span>
                                </el-form-item>
                                <el-form-item label="文章链接：" label-width="82px">
                                    <el-link style="white-space:pre-wrap" :underline='false' :href="props.row.articleUrl" target='_blank'>{{props.row.articleUrl}}</el-link>
                                </el-form-item>
                                <el-form-item label="地区：" label-width="82px">
                                    <span style="white-space:pre-wrap">{{props.row.area}}</span>
                                </el-form-item>
                                <el-form-item label="站点：" label-width="82px">
                                    <span style="white-space:pre-wrap">{{props.row.site}}</span>
                                </el-form-item>
                                <el-form-item label="正文内容：" label-width="82px">
                                    <span style="white-space:pre-wrap">{{props.row.content}}</span>
                                </el-form-item>
                                <el-form-item label="截图：" label-width="82px">
                                    <el-image  v-for="pic in props.row.picList" :key="pic"
                                                style="width:100px;height:100px;margin-right:10px;"
                                               :src="pic" :preview-src-list="props.row.picList">
                                    </el-image>
                                </el-form-item>
                                <el-form-item label="备注：" label-width="82px">
                                    <span style="white-space:pre-wrap">{{props.row.comment}}</span>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="评论员"></el-table-column>
                    <el-table-column prop="author" label="作者"></el-table-column>
                    <el-table-column prop="gmtCreated" label="提交时间">
                    </el-table-column>
                    <el-table-column label="是否推荐">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.recommend"
                                        inactive-color="#e8e8e8"
                                        @change="recommend(scope.row.taskResultId)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="提交状态">
                        <template slot-scope="scope">
                            <el-tag :type="global.CHECK_STATES[scope.row.status].type">{{global.CHECK_STATES[scope.row.status].label}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="deleteScriptDetail(scope.row.taskResultId)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                
                <el-pagination layout="prev,pager,next"
                            :page-size.sync="global.PAGE_SIZE"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            :total="total">
                </el-pagination>
            </div>
            <el-dialog :visible.sync="visible" title="编辑投稿信息" class="container">
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                 <el-form-item label="任务标题：" prop="title">
                     <el-input v-model="form.title"></el-input>
                 </el-form-item>
                 <el-form-item label="任务描述：" prop="description">
                     <el-input type="textarea"
                                :row="3"
                                placeholder="请输入任务描述"
                                v-model="form.description"
                                style="width:75%">
                     </el-input>
                 </el-form-item>
                 <el-form-item label="负责队伍：" prop="targetDepartmentIdList">
                     <el-cascader placeholder="负责队伍"
                                  v-model="form.targetDepartmentIdList"
                                  :options="departments"
                                  :props="{multiple:true}"
                                  collapse-tags
                                  clearable>
                     </el-cascader>
                 </el-form-item>
                 <el-form-item label="起止时间：" prop="datetimeRange">
                    <el-date-picker v-model="form.datetimeRange"
                        type="datetimerange"
                        range-separator="-"
                        start-placeholder="选择起始时间"
                        end-placeholder="选择终止时间"
                        value-format="yyyy-MM-dd hh:mm:ss">
                    </el-date-picker>
                 </el-form-item>
                 <el-form-item label="选择封面：" prop="coverUrl">
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        list-type="picture-card"
                        :http-request="avatarUpload"
                        :before-upload="beforeCoverUpload"
                    >
                        <!-- :on-preview="handlePreview"              
                        > -->
                        <img :src="form.coverUrl" class="avatar">
                    
                    </el-upload>
                 </el-form-item>
                 <el-form-item class="button-group">
                     <el-button type="primary" @click="submitUpdateMission('form')">更新任务</el-button>
                     <el-button @click="visible=false">取消</el-button>
                 </el-form-item>
            </el-form>
        </el-dialog>
        </div>
    </el-container>
</template>

<script>
export default {
    name:'ScriptDetail',
    data(){
        return {
            userInfo:{},
            scriptBasic:{},
            commitCount:0,
            unCommitCount:0,
            scriptDetail:[],
            currentPage:1,
            total:undefined,
            searchData:{
                keyWord:'',
                departmentId:0,
            },
            form:{},
            selectDepartmentOptions:[],
            rules:{
                title:[
                    {required:true,message:'请输入任务名称',trigger:'blur'}
                ],
                description:[
                    {required:true,message:'请输入任务描述',trigger:'blur'}
                ],
                datetimeRange:[
                    {required:true,message:'请选择起止时间',trigger:'change'}
                ],
                targetDepartmentIdList:[
                    {required:true,message:'请选择负责队伍',trigger:'change'}
                ]
            },
            visible:false,
            table_loading:false,
            editAble:true,
        }
    },
    created(){   
        this.init()
    },
    mounted(){
        this.calculateHeight()
    },
    methods:{     
        init(){
            this.table_loading = true
            this.userInfo = this.global.getUserInfo()
            this.searchData.departmentId = this.userInfo.sysRoleCode==2?0:this.userInfo.departmentId
            let p1 = this.$axios.post('/api/task/results',{    
                    departmentId:this.userInfo.sysRoleCode==2?0:this.userInfo.departmentId,     
                    pageNo: 0,
                    pageSize: global.PAGE_SIZE,
                    taskId: this.$route.params.taskId            
            }).then(res=>{
                console.log("detail",res)
                this.scriptDetail = res.data.data.content
                this.total = res.data.data.totalElements
            })
            
            let p2 = this.$axios.get(`/api/task/${this.$route.params.taskId}/with_complete_rate`)
                .then(res=>{
                    console.log(res,'res')
                    this.scriptBasic = res.data.data
                    this.commitCount = this.scriptBasic.commitCount
                    this.unCommitCount  = this.scriptBasic.unCommitCount
                    console.log(this.scriptBasic) 
            })
          
          Promise.all([p1,p2]).then(()=>{
               this.departments = JSON.parse(sessionStorage.getItem('departments'))
               
               if(this.userInfo.sysRoleCode==1&&
                    (this.scriptBasic.targetDepartmentList[0].departmentId!=this.userInfo.departmentId||this.scriptBasic.targetDepartmentList.length>1)){
                    this.editAble = false
                }
                //构建搜索部门的选项
                if(this.userInfo.sysRoleCode==1){
                    this.selectDepartmentOptions = this.departments
                    this.searchData.departmentId = this.userInfo.departmentId
                }
                else{
                    if(this.scriptBasic.targetDepartmentList[0].departmentId==0)
                        this.selectDepartmentOptions = this.departments
                    else
                        this.selectDepartmentOptions = this.global.createSearchDepartment(this.scriptBasic.targetDepartmentList)
                }
        
                console.log('options',this.selectDepartmentOptions)
                console.log('table_data',this.scriptDetail)
                this.table_loading = false
          })
      },
      handleCurrentChange(){
         this.search(true)
      },
      search(isCurrentChange){
          console.log('1')
          console.log(this.searchData.departmentId)
          this.table_loading = true
          if(!isCurrentChange)
            this.currentPage = 1
         
          this.$axios.post('/api/task/results',{
              keyWord:this.searchData.keyWord,
              departmentId:this.searchData.departmentId==0?0:this.searchData.departmentId[1],
              pageNo:this.currentPage-1,
              pageSize:this.global.PAGE_SIZE,
              taskId:this.$route.params.taskId
          }).then(res=>{
                    console.log('resss',res)
                    this.scriptDetail = res.data.data.content
                    this.total = res.data.data.totalElements
            })
            // .catch(err=>{
            //     console.log(err)
            //     this.$message.error(err)
            //     this.table_loading = false
            // })
            .finally(()=>{
                this.table_loading = false;
            })
      },
      selectedDepartment(departments){
          let res = []
          if(departments[0].departmentId==0){
              for(let department of this.departments){
                  for(let item of department.children){
                      res.push([department.value,item.value])
                  }
              }
          }else{
              for(let item of departments)
                res.push([item.areaName,item.departmentId])
          }
          return res
      },
      beforeCoverUpload(file){
          const IMG_TYPE = ['image/jpg', 'image/jpeg', 'image/png']
            const isImg = IMG_TYPE.includes(file.type)
            const size = file.size / 1024 / 1024 < 10

            if (!isImg) {
                this.$message.error('请选择图片作为头像!');
            }
            if (!size) {
                this.$message.error('上传头像图片大小不能超过10MB!');
            }
            console.log(isImg&& size)
            return isImg && size;
      },
      avatarUpload(file){
          console.log('upload',file)
            let formData = new FormData()
            formData.append('file',file.file)
            this.dialog_loading = true
            this.$axios.post('/api/file/upload',formData).then(res=>{
                console.log('res',res)
                this.form.coverUrl = res.data.data
                this.$message.success('图片上传成功')
            })
            // .catch(err=>{
            //     console.log('err',err.response)
            //     this.$message.error('上传失败')
            //     this.dialog_loading = false
            // })
            .finally(()=>{
                this.table_loading = false;
            })
      },
      submitUpdateMission(formName){
          this.$refs[formName].validate(valid=>{
              if(valid){
                  let formattedTargetDepartmentIdList = []
                  for(let item of this.form.targetDepartmentIdList)
                    formattedTargetDepartmentIdList.push(item[1])
                  this.$axios.put('/api/task/update',{
                      title:this.form.title,
                      description:this.form.description,
                      startTime:this.form.datetimeRange[0],
                      endTime:this.form.datetimeRange[1],
                      targetDepartmentIdList:formattedTargetDepartmentIdList,
                      coverUrl:this.form.coverUrl,
                      taskId:this.$route.params.taskId
                  }).then(res=>{
                      console.log(res)
                      this.scriptBasic = res.data.data
                      this.$message.success('更新成功')
                      this.visible = false
                  })
                //   .catch(err=>{
                //       this.$message.error(err)
                //       this.dialog_loading = false
                //   })
                  .finally(()=>{
                    this.table_loading = false;
                  })
              }
          })
      },
      recommend(taskResultId){
          console.log('tri',taskResultId)
          this.$axios.put(`/api/result/${taskResultId}/recommend`).then(res=>{
              console.log(res.data.data)
              console.log(this.scriptDetail)
              this.$message.success(res.data.data.recommend?'推荐成功':'取消推荐成功')
          })
      },
      deleteScriptDetail(taskResultId) {
        console.log(taskResultId);
        this.$confirm('是否删除该投稿记录？','提示',{
            confirmButtonText:'确定',
            cancelButtonText:'取消',
            type:'warning'
        }).then(()=>{
            this.$axios.delete(`/api/result/${taskResultId}`).then((res)=>{
                console.log(res.data);
                if (res.data.code === 200) {
                this.$message({
                    type:'success',
                    message:'删除成功！'
                });
                this.search(false);
                }
            })
        })
        },
    calculateHeight(){
        console.log(this.$refs['des'],'test11');
        let imgHeight = 100 - this.$refs.des.offsetHeight
        this.$refs.img.setAttribute('offsetHeight',imgHeight)
    }
  },
  watch:{
      visible(newVal){
          if(newVal){
              this.form = {
                    title:this.scriptBasic.title,
                    description:this.scriptBasic.description,
                    datetimeRange:[this.scriptBasic.startTime,this.scriptBasic.endTime],
                    coverUrl:this.scriptBasic.coverUrl,
                    targetDepartmentIdList:this.selectedDepartment(this.scriptBasic.targetDepartmentList)
              }
              console.log(this.form)
          }
      }
  }
}
</script>

<style scoped>
.table-expand label {
  width: 90px;
  color: #99a9bf;
}
</style>

<style src='../../../assets/css/secondHeader.css' scoped>
</style>
<style src='../../../assets/css/table.css' scoped>
</style>
<style src='../../../assets/css/dialog.css' scoped>
</style>

<style src='../../../assets/css/detailHeader.css' scoped>
</style>